h1 {
  font-family: "Bangers", cursive;
  font-size: 5rem;
  text-shadow: 2px 2px limegreen;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  background: #f953c6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #b91d73,
    #f953c6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #b91d73,
    #f953c6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.image {
  max-width: 75%;
}
